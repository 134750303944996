export const APP_IDS = {
  CS: '730'
}

export const dayList = [
  {
    label: '1天',
    value: 1
  },
  {
    label: '7天',
    value: 2
  },
  {
    label: '15天',
    value: 3
  },
  {
    label: '30天',
    value: 4
  }
]